import { globalStyles } from "@/assets/styles/global.ts";
import { theme } from "@/assets/styles/theme.ts";
import { AppLoader } from "@/components/AppLoader";
import { Layout } from "@/layout/Layout";
import { router } from "@/lib/router";
import { HomePage } from "@/pages";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

const AsyncLibraryPage = React.lazy(() => import("@/pages/library"));
const AsyncLibraryItemPage = React.lazy(() => import("@/pages/library/article"));
const AsyncContactsPage = React.lazy(() => import("@/pages/contacts"));
const AsyncLapinPage = React.lazy(() => import("@/pages/lapin"));
const AsyncCatalogPage = React.lazy(() => import("@/pages/catalog"));
const AsyncMonkeyPopup = React.lazy(() => import("@/pages/catalog/monkey"));
const AsyncPage404 = React.lazy(() => import("@/pages/404"));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={globalStyles} />
      <Box fontSize={{ xs: "0.9rem", md: "1rem" }}>
        <Suspense fallback={<AppLoader />}>
          <Routes>
            <Route
              path={"/"}
              element={<Layout />}
            >
              <Route
                path={router.home}
                element={<HomePage />}
              />
              <Route
                path={router.lapin}
                element={<AsyncLapinPage />}
              />
              <Route
                path={router.library}
                element={<AsyncLibraryPage />}
              />
              <Route
                path={`${router.library}/:id`}
                element={<AsyncLibraryItemPage />}
              />
              <Route
                path={router.contacts}
                element={<AsyncContactsPage />}
              />
              <Route
                path={router.catalog}
                element={<AsyncCatalogPage />}
              >
                <Route
                  path={"monkey/:id"}
                  element={<AsyncMonkeyPopup />}
                />
              </Route>
              <Route
                path={"*"}
                element={<AsyncPage404 />}
              />
            </Route>
          </Routes>
        </Suspense>
      </Box>
    </ThemeProvider>
  );
}

export default App;
